.curve-mask-top,
.curve-mask-top-product-banner {
  clip-path: url(#curve-mask-top-mobile);
}

.curve-mask-bottom,
.curve-mask-bottom-product-banner {
  clip-path: url(#curve-mask-bottom-mobile);
}

.curve-mask-inverted {
  clip-path: url(#curve-mask-mobile-inverted);
}

.curve-banner-2 {
  clip-path: url(#curve-banner-2);
}

.curve-banner-2-mobile {
  clip-path: url(#curve-banner-2-mobile);
}

.curve-banner-2-mobile-top {
  clip-path: url(#curve-banner-2-mobile-top);
}

.curve-sides-inwards {
  clip-path: url(#curve-sides-inwards);
}

.curve-sides-inwards-reverse {
  clip-path: url(#curve-sides-inwards-reverse);
}

.curve-both-sides-inset {
  clip-path: url(#curve-sides-inwards-reverse);
}

.h-bannerShort .curve-mask-bottom,
.h-bannerShort .curve-mask-bottom-product-banner {
  height: 92%;
}

.curve-full-bleed {
  clip-path: url(#curve-full-bleed);
}

@media (min-width: 480px) {
  .curve-mask-top,
  .curve-mask-top-product-banner {
    clip-path: url(#curve-mask-top-tablet);
  }

  .curve-mask-bottom,
  .curve-mask-bottom-product-banner {
    clip-path: url(#curve-mask-bottom-tablet);
  }
}

@media (min-width: 768px) {
  .curve-mask-top-product-banner {
    clip-path: url(#curve-mask-top-tab);
  }

  .curve-mask-bottom-product-banner {
    clip-path: url(#curve-mask-bottom-tab);
  }

  .curve-mask-top {
    clip-path: url(#curve-mask-top-desktop);
  }

  .curve-mask-bottom {
    clip-path: url(#curve-mask-bottom-desktop);
  }
}

@media (min-width: 1024px) {
  .curve-mask-top,
  .curve-mask-top-product-banner {
    clip-path: url(#curve-mask-top-desktop);
  }

  .curve-mask-bottom,
  .curve-mask-bottom-product-banner {
    clip-path: url(#curve-mask-bottom-desktop);
  }

  .curve-mask-inverted {
    clip-path: url(#curve-mask-desktop-inverted);
  }
}
