@media print {
  @page {
    size: A4 portrait;
    margin: 20px 0;
  }

  @page:first {
        @bottom-left {
            content: "  Page " counter(page) " / " counter(pages);
            font-size: 10px;
            color: var(--color-text, #292b29);
            font-family: var(--font-neutral), Arial;
            text-align: right;
            padding-right: 20px;
        }
  }

  @page {
        @bottom-left {
            content: "  Page " counter(page) " / " counter(pages);
            font-size: 10px;
            color: var(--color-text, #292b29);
            font-family: var(--font-neutral), Arial;
            text-align: right;
            padding-right: 20px;
        }
  }

  body {
    margin: auto;
    height: auto;
    line-height: 1.5;
  }

  html {
    overflow: visible;
    height: auto;
  }

  thead.table-header {
    display: table-header-group;
  }

  .table-footer {
    display: table-footer-group;
  }

  .curve-mask-bottom {
    z-index: initial !important;
  }

  .recipeInstructions {
    max-height: min-content !important;
    overflow-y: hidden !important;
  }
  .accordion__item {
    margin-bottom: 2rem !important;
  }
  .accordion__item .animate-fadeIn {
    margin-top: -3rem !important;
  }
  .print-col-span-8 .ingredientWrapper > div {
    margin-bottom: 2rem !important;
    margin-top: -1rem !important;
  }

  .print-col-span-8 .ingredientWrapper .ingredientText,
  .print-col-span-8 .ingredientWrapper,
  .accordion__item .animate-fadeIn > div > div > p,
  .accordion__item .animate-fadeIn > div,
  div[data-print='hide-before-content'] > div > div,
  .print-col-span-8 .accordion__item {
    page-break-inside: avoid !important;
  }
  .print-col-span-8 .ingredientWrapper .ingredientText::before,
  .print-col-span-8 .ingredientWrapper::before,
  div[data-print='hide-before-content'] > div > div::after,
  .print-col-span-8 .accordion__item::before {
    content: '';
    display: block !important;
    height: 100px !important;
    margin-bottom: -100px !important;
  }

  .print-col-span-8,
  #inner-ingredients-tab-container,
  .accordion__item .animate-fadeIn {
    page-break-before: auto;
    page-break-after: auto;
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    -webkit-region-break-inside: avoid;
    position: relative;
    display: block !important;
    overflow: visible !important;
    height: auto !important;
  }
  div[data-print='hide'],
  footer[data-print='hide'],
  section[data-print='hide'],
  svg[data-print='hide'],
  span[data-print='hide'],
  .slick-dots {
    display: none !important;
  }

  div[data-print='make-non-sticky'] {
    position: unset;
  }

  div[data-print='text-visible'],
  div[data-print='text-visible'] div,
  div[data-accordion-component='AccordionItemPanel'] {
    -webkit-animation: none;
    animation: none;
    display: block !important;
    color: #000;
    visibility: visible;
  }

  div[data-print='hide-before-content']::before {
    content: '' !important;
  }

  div[data-print='show'],
  div[itemprop='recipeInstructions'] {
    display: block !important;
    overflow: visible !important;
    height: auto !important;
    max-height: none !important;
  }

  div[data-print='margin-1-rem'] {
    margin-bottom: 1rem;
  }
  div[data-print='set-min-height'] {
    min-height: 100vh;
  }

  div[data-print='hide-child'] {
    background: #fff;
  }

  div[data-print='invisible'] {
    opacity: 0;
  }

  .print-columns-16 {
    grid-template-columns: repeat(16, minmax(0, 1fr));
  }

  .print-col-span-8 {
    grid-column: span 8 / span 8;
  }
  #usercentrics-root {
    display: none;
  }

  .accordion__button {
    display: block !important;
  }

  .accordion__button > div {
    float: left;
  }

  .slick-list {
    max-width: none !important;
    width: 120% !important;
  }

  .slick-track {
    width: 100% !important;
  }

  .slick-track .slick-slide {
    width: 100% !important;
  }

  #inner-ingredients-tab-container {
    width: 95%;
  }
}
