@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .space-next-any-element + * {
    margin-top: var(--spacing-between-elements, 5rem);
  }

  .space-next-any-element .spacer-anchor {
    display: none;
  }
}

@layer base {
  :root {
    /* Colors */
    --darkgray: #292b29;
    --white: #ffffff;
    --green: #bbc4ba;
    --gray-300: #252525;
    --gray-500: #5a5e5a;
    --lightgray: #e8ebe8;
    --green-500: #8d9c8c;
    --green-curve: #d4ded3;
    --green-default: #bbc4ba;
    --light-beige: #f7e9d5;
    --primary: #a45a2a;

    /* Happy Cart */
    --color-hc-primary: var(--primary);
    --color-hc-hover: var(--primary);
    --color-hc-text: var(--white);
  }
}

@layer components {
  .title-style-xl {
    @apply font-primary text-5xl leading-11 font-bold md:text-8xl md:leading-14;
  }

  .title-style-lg {
    @apply font-primary text-4xl leading-9.5 font-bold md:text-7xl md:leading-12;
  }

  .title-style-md {
    @apply font-primary text-2xxl leading-8.5 font-bold lg:text-6xl lg:leading-11.5;
  }

  .title-style-sm {
    @apply font-primary text-xl leading-7.5 font-medium md:text-4xl md:leading-10.5;
  }

  .title-style-xs {
    @apply font-primary text-lg leading-7 font-medium md:text-2xl md:leading-8.5;
  }

  .title-style-xxs {
    @apply font-primary text-md leading-6.5 font-medium md:text-xl md:leading-7.5;
  }

  .title-style-xxxs {
    @apply font-primary text-lg leading-7 font-medium;
  }

  .title-style-august {
    @apply font-secondary text-brown text-5xl leading-7.5 md:text-8xl md:leading-72;
  }

  .text-style-xxl {
    @apply font-neutral text-2xxl;
  }

  .text-style-xl {
    @apply font-neutral text-2xl;
  }

  .text-style-lg {
    @apply font-neutral text-base md:text-md;
  }

  .text-style-md {
    @apply font-neutral text-sm md:text-base tracking-wide;
  }

  .text-style-sm {
    @apply font-neutral text-xs md:text-sm;
  }

  .text-style-xs {
    @apply font-neutral text-sm md:font-light;
  }

  .text-style-lead {
    @apply font-neutral text-xs leading-4 uppercase tracking-xl lg:text-sm lg:leading-4.5;
  }

  .text-style-leadxs {
    @apply font-neutral text-xss leading-4 uppercase tracking-xl md:text-xs;
  }

  .text-style-link {
    @apply font-neutral text-base font-medium uppercase tracking-widest leading-none;
  }

  .text-style-list {
    @apply font-neutral text-sm leading-5.5 md:text-xl md:leading-7.5;
  }

  .text-style-listsm {
    @apply font-primary font-medium text-lg leading-6;
  }

  .step-links a {
    @apply underline;
  }
}

html {
  scroll-padding-top: 100px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary), Arial;
  font-weight: 500;
}

h1,
h2,
h3 {
  font-weight: 700;
}

body {
  font-family: var(--font-neutral), Arial;
  overflow-x: hidden;
  background-color: var(--color-body, #fff);
  color: var(--color-text, #292b29);
}

@media only percy {
  .hide-in-percy {
    visibility: hidden;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.swiper-pagination-bullet {
  width: 13px !important;
  height: 13px !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--darkgray);
  width: 13px !important;
  height: 13px !important;
}
